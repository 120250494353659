import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
let ErrorPage = class ErrorPage extends Vue {
    constructor() {
        super(...arguments);
        this.message = this.$route.query.message || 'Unknown error';
    }
    gotoHome() {
        this.$router.push('/');
    }
};
ErrorPage = __decorate([
    Component({})
], ErrorPage);
export default ErrorPage;
